import Container from 'app/components/Container'
import DoubleGlowShadow from 'app/components/DoubleGlowShadow'
import React, { FC } from 'react'

import DefaultLayout from './Default'

export interface Layout {
  id: string
}

export const SwapLayoutCard: FC = ({ children }) => {
  return (
    <div
      style={{
        background:
          'linear-gradient(#171616 0 0) padding-box,linear-gradient(135deg, #ff793b 0%, #ffbd11 18%, #93ff40 36%, #36eaff 54%, #2d82ff 72%, #ff40f3 89%, #ff005f 100%) border-box',
        border: '2px solid transparent',
        borderRadius: '10px',
      }}
      className="flex flex-col gap-3 p-2 pt-4 md:p-4"
    >
      {children}
    </div>
  )
}

export const Layout: FC<Layout> = ({ children, id }) => {
  return (
    <DefaultLayout>
      <Container id={id} className="py-4 md:py-12 lg:py-[120px] px-2" maxWidth="md">
        <DoubleGlowShadow>{children}</DoubleGlowShadow>
      </Container>
    </DefaultLayout>
  )
}

type SwapLayout = (id: string) => FC
export const SwapLayout: SwapLayout = (id: string) => {
  return (props) => <Layout id={id} {...props} />
}
