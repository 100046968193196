
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/legacy/swap/[[...tokens]]",
      function () {
        return require("private-next-pages/legacy/swap/[[...tokens]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/legacy/swap/[[...tokens]]"])
      });
    }
  